import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(value: string, seperator: string, limit?: number): string[] {
    return value.split(seperator, limit);
  }
}
