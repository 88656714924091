import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tgLabel],[tg-input-label]',
})
export class TgLabelDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    if (el.nativeElement && !el.nativeElement.classList.contains('tg-input-label')) {
      el.nativeElement.classList.add('tg-input-label');
    }
  }
}
