import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mouseOverClass]'
})
export class MouseOverClassDirective {
  constructor(public elementRef: ElementRef) {}
  @Input('mouseOverClass') mouseOverClass: string | string[];
  @Input('mosueOverOptions') mouseOverOptions: MouseOverOptions = <MouseOverOptions>{};

  @HostListener('mouseenter', ['$event']) onMouseEnter(ev: MouseEvent) {
    if (this.mouseOverOptions.onlyDrag === true && ev.buttons !== 1) return;
    this.elementRef.nativeElement.classList.add(this.mouseOverClass);
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.mouseOverClass);
  }
  @HostListener('mouseup', ['$event']) onMouseDrop() {
    if (this.mouseOverOptions.onlyDrag) {
      this.onMouseLeave();
    }
  }
}
export interface MouseOverOptions {
  onlyDrag?: boolean;
}
